'react'에서 *를 React로 가져옵니다;

유형 Machine<s> = { [k: string]: { [k: string]: S } };
type MachineState<t> = T의 키
유형 MachineEvent<t> = UnionToIntersection의 키<t[keyof T]="">;

// 🤯 https://fettblog.eu/typescript-union-to-intersection/
유형 유니온투인터섹션<t> = (T extends any ? (x: T) => any : never) extends (x: infer R) => any
  ? R
  : never;

함수 useStateMachine 내보내기<m>(
  initialState: MachineState<m>,
  기계: M & 기계<machinestate<m>>
) {
  return React.useReducer((state: MachineState<m>이벤트: 머신이벤트<m>): 머신 상태<m> => {
    const nextState = (machine[state] as any)[event];
    return nextState ?? state;
  }, initialState);
}
</m></m></m></machinestate<m></m></m></t></t[keyof></t></t></s>